import { Injectable } from '@angular/core';

import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';
import { UHQLApiService } from '@frontend/unhideschool/app/api-gateway/services/uhql-api.service';

import { userSearchSchema } from '@frontend/unhideschool/shared/models/uhql-schemas/user-search.schema';
import { mentorUsersQuery } from '@frontend/unhideschool/shared/models/graphql/mentor-users.query';
import { GraphqlFilterItem } from '@frontend/unhideschool/shared/models/graphql/base-graphql';

import {
  getInterestCategoriesPerfil,
  getWebSiteCategoryQuery,
  getWebsitesByUserQuery,
  updateUserPerfilQuery,
  myCoursesLastWatchedQuery,
} from '../../models/graphql/perfil-query';

import { map } from 'rxjs/operators';

import {
  UHQLRequestFilter,
  UHQLRequestOptions,
  UHQLRequestSchema,
  UNHUserProfile,
  UserData
} from '@frontend/unhideschool/app/core/models';

import { Course } from '@frontend/unhideschool/graphql-schema';
import { meCoverImage, meGallery } from '../../models/graphql/me.query';
import { getUserArtworks } from '../../../modules/user/graphql/queries/get-artworks.query';
import { MyCourses } from '@frontend/unhideschool/shared/models/project/my-courses.model';
import { getUserDataQuery } from '@frontend/unhideschool/app/modules/user/graphql/queries';
import { meProfileQuery } from '@frontend/unhideschool/app/core/models/graphql';

const removeComingsoonCourse = courses => courses?.filter(course => {
  return !course.traits.some(trait => trait.internalname === 'comingsoon')
});

const transformToMyCourses = courses => {
  if (!courses) {
    return null;
  }

  return courses.reduce((acc, course) => {
    if (!course.active) {
      return acc;
    }

    return [...acc, new MyCourses(course)];
  }, [] as MyCourses[]);
};

type MeProfile = { user: UNHUserProfile, subscribed: boolean; };

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    private uhql: UHQLApiService,
    private graph: GraphqlApiService
  ) { }

  getUserProfileInfo() {
    return this.graph.graphqlRequest<'me', MeProfile>(meProfileQuery).pipe(
      map(res => res.data.me)
    );
  }

  getUserDataById(uid: number) {
    return this.graph
      .graphqlRequest<'Users', UserData[]>(getUserDataQuery, { uid })
      .pipe(map(res => res.data.Users[0]))
  }

  updateShortBio(userid: number, shortbio: string) {
    return this.graph
      .graphqlRequest(updateUserPerfilQuery, { userid, shortbio })
      .pipe(map((res) => res.data.UpdateUserProfile));
  }

  getUserLoggedCover() {
    return this.graph
      .graphqlRequest<'me', { user: { coverpicture: string; } }>(meCoverImage)
      .pipe(map(res => res.data.me.user.coverpicture));
  }

  getUserArtworks(uid: number, communityid: number) {
    const variables = { uid, communityid };
    return this.graph
      .graphqlRequest<'Posts', any[]>(getUserArtworks, variables)
      .pipe(map(res => res.data.Posts));
  }


  getGuardianCareersTopics(uid: number, communityid: number) {
    const variables = { uid, communityid };
    return this.graph
      .graphqlRequest<'Posts', any[]>(getUserArtworks, variables)
      .pipe(map(res => res.data.Posts));
  }

  getMyCoursesNormalUser(pagination) {
    const variabes = { ...pagination };

    return this.graph.graphqlRequest<'MyCourses', { courses: Course[] }>(
      myCoursesLastWatchedQuery,
      variabes
    ).pipe(
      map(res => res.data.MyCourses.courses),
      map(removeComingsoonCourse),
      map(transformToMyCourses)
    )
  }

  getAllInterestCategories() {
    return this.graph
      .graphqlRequest<'drUserInterestCategories', { name: string, interestid: string, active: boolean }[]>(
        getInterestCategoriesPerfil
      ).pipe(map((res) => res.data.drUserInterestCategories));
  }

  getUserIntereset() {
    return this.graph
      .graphqlRequest(getInterestCategoriesPerfil)
      .pipe(map((res) => res.data.drUserInterestCategories));
  }

  getGalleryUser() {
    return this.graph
      .graphqlRequest<'me', { user: any }>(meGallery)
      .pipe(map((res) => res.data.me.user));
  }

  getWebSiteCategories() {
    return this.graph
      .graphqlRequest<'drUserWebsiteCategories', any>(getWebSiteCategoryQuery)
      .pipe(map(res => res.data.drUserWebsiteCategories));
  }

  getUsersByEmail(searchtext) {
    const schema: UHQLRequestSchema = userSearchSchema;

    const filters: UHQLRequestFilter[] =
      [{ field: 'email', op: 'like', value: `%${searchtext}%` }];

    const opts: UHQLRequestOptions = { perpage: 3 };
    return this.uhql.uhqlRequest('LIST', 'USERS', schema, filters, opts).pipe(
      map(res => res as { dict: any })
    );
  }

  getMentorUsers() {
    const query: string = mentorUsersQuery;

    const filters: GraphqlFilterItem[] = [
      { roleid: { op: 'EQ', v: 3 } },
      { uid: { op: 'NOTIN', vl: [4637, 7137, 911] } }
    ];

    return this.graph
      .graphqlRequest<'Users', any>(query, { filters })
      .pipe(map((res) => res.data.Users));
  }

  updateSocialMediaUser(currentSocialMedia: any, userid: number) {
    return this.graph.graphqlRequest(
      updateUserPerfilQuery,
      { userid, websites: currentSocialMedia }
    );
  }

  updateUserData(variables) {
    return this.graph.graphqlRequest<'UpdateUserProfile', { user: any }>(
      updateUserPerfilQuery,
      variables
    ).pipe(map((res) => res.data.UpdateUserProfile.user));
  }

  getWebsitesByUser(userid: number) {
    return this.graph.graphqlRequest<'UserProfile', { websites: any }>(
      getWebsitesByUserQuery,
      { uid: userid}
    ).pipe(map(res => res.data.UserProfile.websites));
  }

  getUserPurchases() {
    const query = `
      query MePurchases {
        me {
          purchases {
            orderproduct {
              product {
                productid
              }
            }
          }
        }
      }
    `;

    return this.graph
      .graphqlRequest<'me', { purchases: any[] }>(query)
      .pipe(
        map(res =>res.data.me.purchases.map(p => {
          return p.orderproduct.product.productid;
        }))
      );
  }
}
