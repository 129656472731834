import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';

import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { of, throwError } from 'rxjs';
import {
  FetchPricingData,
  FetchInvitationCoupon,
  FetchUserCoursesIds,
  SetSubscriptionWarning,
  UpdateSidebarToggle
} from './application.actions';
import {
  ActivePromotion,
  ApplicationStateModel,
  defaultApplicationStateModel
} from './application.model';
import { fetchUserCoursesIds } from './graphql/get-user-courses';
import { getCampaignQuery } from './graphql/fetch-campaign.query';

import { GraphqlApiService } from '../../api-gateway/services/graphql-api.service';
import { NormalizedSubscriptionPlan } from '../../modules/membership/models/membership-normalized-plan.model';
import { CampaignQueryPayload } from './model/query-payload/campaign-payload.model';
import { FetchPlans } from '../../modules/membership/ngxs/membership.action';
import { fetchSubscriptionPlansProductQuery } from '../../modules/membership/graphql/queries';
import { Subscriptionplan } from '../../modules/membership/models/membership-root-plan.model';
import { getActivePromotionCouponQuery } from './graphql/get_active_promottion_coupon.query';

@State<ApplicationStateModel>({
  name: 'applicationState',
  defaults: defaultApplicationStateModel,
})
@Injectable()
export class ApplicationState {
  constructor(private graph: GraphqlApiService, private store: Store) { }

  @Action(SetSubscriptionWarning)
  setSubscriptionWarning(
    ctx: StateContext<ApplicationStateModel>,
    action: SetSubscriptionWarning
  ) {
    ctx.patchState({
      subscriptionWarning: action.payload,
    });
  }

  @Action(FetchPricingData)
  fetchPricingData(ctx: StateContext<ApplicationStateModel>, action: FetchPricingData) {
    // TODO: Get active promotion and set state with DiscountCoupon
    return this.graph.graphqlRequestAll<'Campaign', CampaignQueryPayload>(getCampaignQuery)
      .pipe(
        map(res => res.data.Campaign.all.items),
        switchMap(([campaign]) => this.initializeCampaign(campaign, ctx)),
        switchMap(() => this.initializeInviteCoupon(ctx, action)),
        switchMap(() => this.handleFetchSubscriptionPlans(ctx)),
        switchMap(() => this.initializeAnualPlan(ctx, ctx.getState().subscriptionPlans, ctx.getState().activepromotion)),
        catchError(() => this.handleFetchPricingDataError(ctx))
      );
  }

  @Action(UpdateSidebarToggle)
  UpdateSidebarToggle(
    ctx: StateContext<ApplicationStateModel>,
    action: UpdateSidebarToggle
  ) {
    ctx.patchState({
      sidebar: {
        open: !action.toggleState,
      },
    });
  }

  @Action(FetchUserCoursesIds)
  FetchUserCoursesIds(ctx: StateContext<ApplicationStateModel>) {
    return this.graph.graphqlRequest<'me', any>(fetchUserCoursesIds).pipe(
      map(res => res.data.me.courses),
      map((courses: { postid: string }[]) => {
        const coursesIdList = courses.map(course => Number(course.postid))
        return ctx.patchState({ user: { coursesIdList } });
      })
    );
  }

  @Action(FetchPlans)
  fetchPrices(ctx: StateContext<ApplicationStateModel>) {
    // return this.handleFetchSubscriptionPlans(ctx);
  }

  @Action(
    FetchInvitationCoupon
  )
  fetchInvitationCoupon(
    ctx: StateContext<ApplicationStateModel>,
    action: FetchInvitationCoupon) {
    return this.graph.graphqlRequest<'DiscountCoupon', { item: any }>(getActivePromotionCouponQuery,
      {
        couponcode: action.couponcode
      }).pipe(
        map(res => res.data.DiscountCoupon.item),
        tap((coupon) => {
          if (coupon) {
            const activePromotion = {
              active: true,
              initialized: true,
              global: false,
              coupon,
            }
            ctx.patchState({
              activepromotion: activePromotion,
            })
          }
        })
      )
  }
  handleFetchSubscriptionPlans(ctx: StateContext<ApplicationStateModel>) {
    return this.graph.graphqlRequestAll<'SubscriptionPlan', any>(fetchSubscriptionPlansProductQuery).pipe(
      map(res => res.data.SubscriptionPlan?.all?.items),
      tap((subsPlans: Subscriptionplan[]) => {
        const sp = subsPlans.map(subsplan => new NormalizedSubscriptionPlan(subsplan));
        ctx.patchState({
          subscriptionPlans: sp
        })
      })
    )
  }

  initializeCampaign(campaign, ctx: StateContext<ApplicationStateModel>) {
    if (!campaign) {
      return of('')
    }
    ctx.patchState({
      activepromotion: { initialized: true, active: true, coupon: campaign?.discountCoupon },
    });
    return of(campaign?.discountCoupon);
  }

  initializeAnualPlan(ctx: StateContext<ApplicationStateModel>, plans: NormalizedSubscriptionPlan[], activePromotion: ActivePromotion) {
    const anualPlan = plans.find(plan => plan.internalname === 'anual')
    ctx.patchState({
      anualPlan: anualPlan
    });
    if (!anualPlan) {
      return throwError(() => new Error('Error on initializing anual plan state'));
    }
    return of('')
  }

  handleFetchPricingDataError(ctx: StateContext<ApplicationStateModel>) {
    ctx.patchState({
      activepromotion: { initialized: true, active: false, coupon: null, global: false },
    });
    return of('Não Há Promoção')
  }

  private initializeInviteCoupon(ctx, action) {
    if (!action.inviteCouponcode) {
      return of('')
    }
    return this.fetchInvitationCoupon(ctx, new FetchInvitationCoupon(action.inviteCouponcode))
  }
}
