<div class="footer-container">
  <div class="content-copyright mobile">
    <span>{{pageTranslations?.footerContainerTranslation?.copyrightTextAfterYear}} ©{{year}} {{pageTranslations?.footerContainerTranslation?.copyrightTextBeforeYear}}</span>
    <span>
      <a
        class="terms"
        [href]="policyUsageUrl"
        rel="noreferrer nofollow"
        target="_blank"
      >{{pageTranslations?.footerContainerTranslation?.usagePolicyText}}</a>
    </span>

  </div>

  <div class="content-copyright desktop">
    <span>{{pageTranslations?.footerContainerTranslation?.copyrightTextAfterYear}} ©{{year}} {{pageTranslations?.footerContainerTranslation?.copyrightTextBeforeYear}}</span>
    <div class="divider">|</div>

    <span>
      <a
        class="terms"
        [href]="policyUsageUrl"
        target="_blank"
      >{{pageTranslations?.footerContainerTranslation?.usagePolicyText}}</a>
     
    </span>
  </div>

  <div class="flex-spacer"></div>

  <a class="unh-button-old faq" theme="transparent-grey" href="https://help.unhideschool.com/pt-BR/"  target="_blank"
  >
  {{pageTranslations?.footerContainerTranslation?.faqText}}
  </a>

  <unh-social-menu></unh-social-menu>
</div>
