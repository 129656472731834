export const fetchSubscriptionPlansProductQuery = `
query FetchSubscriptionPlans {
  SubscriptionPlan {
    all(onlyRootPlans: true, active: true) {
      items {
        subsplanid
        name
        internalname
        description
        planfrequencyid
        frequencyInterval
        entitlementid
        entitlementDuration
        rootplanid
        datecreated
        dateupdated
        active
        enabled
        currentPrice
        originalPrice
        campaign {
          campaignName
          active
          discountCouponId
          discountCoupon {
            active
            name
            couponcode
            value
            cycles
            validuntil
            datecreated
            discountcoupontype {
             internalname
            }
          }
        }
        discountcoupons {
          couponcode
        }
        prices {
          price
          subsplanpriceid
        }
        planfrequency {
          planfrequencyid
          internalname
          seconds
        }
        additionalinfo
      }
    }
  }
}

`;
