export const PostTypeConfig = {
  COURSE: {
    id: 1,
    name: 'curso',
    viewPath: '/post',
    viewAllPath: '/curso',
  },
  TUTORIAL: {
    id: 7,
    name: 'tutorial',
    viewPath: '/tutorial/view',
    viewAllPath: 'null',
  },
  PACK: {
    id: 8,
    name: 'pack',
    viewPath: '/pack/view',
    viewAllPath: '/pack/view-all',
  },
};

export interface IPostTypeConfig {
  id: number;
  name: string;
  viewPath: string;
  viewAllPath: string;
}
