
/**
 * Graphql Query | ProjectPosts
 * ***
 * @filters posttypeid, active, published
 * @vars page, per_page
 * ***
 * Essa query aplica 3 filtros padrões para trazer filtrar os posts
 * de projeto e não aceita argumentos.
 */
export const projectPostsQuery = `
    query ProjectPosts($page: Int, $perpage: Int) {
        Posts(
            page: $page,
            perpage: $perpage,
            filters: [
                {posttypeid:{op:EQ,v:1}},
                {active:{op:EQ,v:true}},
                {published:{op:EQ,v:true}}
            ],
            orderBy: {f:position,o:ASC}
        ) {
            creatoruid
            postid
            position
            description
            fulldescription
            datepublished
            dateupdated
            postthumbnail
            published
            title
            headline
            contenttime

            postviewallhead
            postthumbnail
            postmobilecover
            postthumbnailcover
            galleries {
                galleryid
                name
                internalname
                rootgalleryid
                orderedpostpartid
                coverid
                datecreated
                dateupdated
                active
              }
            orderedpostparts {
              orderedpostpartid
              postpartid
              postpart {
                subtitle
                postparttype{
                    internalname
                  }
              }
            }

            creator {
                fullname,
                profilepicture,
                occupationtitle,
                shortbio,
                uid,
                alias

      relatedmedia{
        render

        imagesettings {
          imagesettingid
          width
          height
          waspectratio
          haspectratio
          xcenter
          ycenter
        }

        mediacontenttype {
          internalname
        }
      }
            }

            tags {
                tagid
                name
                tagtype {
                    internalname
                }
            }

            traits {
                name
                internalname
            }

            product {
                productid
                active

                prices {
                    productpriceid
                    price
                }

                discountcoupons {
                    couponcode
                }
            }

        }
    }
`;


export const customProjectPostQuery = `
    query customProjectPostQuery(
        $tags: [Int],
        $page: Int,
        $perpage: Int
    ){
        homeposts(tags: $tags, page: $page, perpage: $perpage){
          posts{
                creatoruid
                postid
                position
                datepublished
                dateupdated
                postthumbnail
                contenttime
            published
            active
                title
                postviewallhead
                postthumbnail
                postmobilecover
                postthumbnailcover
                creator {
                    fullname,
                    profilepicture,
                    occupationtitle,
                    shortbio,
                    uid,
                    alias
                    relatedmedia{
                      render

                      imagesettings {
                        imagesettingid
                        width
                        height
                        waspectratio
                        haspectratio
                        xcenter
                        ycenter
                      }

                      mediacontenttype {
                        internalname
                      }
                    }
                  }

                tags {
                    tagid
                    name
                    tagtype {
                        internalname
                    }
                }

                traits {
                    name
                    internalname
                }

                product {
                    productid
                    active

                    prices {
                        productpriceid
                        price
                    }

                    discountcoupons {
                        couponcode
                    }
                }

            }
            totalCount
            pageCount
            page
        }
      }
`;

export const customProjectPostQueryHighlight = `
  query GetCustomProjectPostQueryInListHighlight($page: Int, $perpage: Int, $highlightPosts: [Int]) {
    Posts(page: $page, perpage: $perpage, filters: [{postid: {op: IN, vl: $highlightPosts}}]) {
      creatoruid
      postid
      position
      datepublished
      dateupdated
      postthumbnail
      contenttime
      published
      active
      title
      postviewallhead
      postthumbnail
      postmobilecover
      postthumbnailcover
      creator {
        fullname
        profilepicture
        occupationtitle
        shortbio
        uid
        alias
        relatedmedia {
          render
          imagesettings {
            imagesettingid
            width
            height
            waspectratio
            haspectratio
            xcenter
            ycenter
          }
          mediacontenttype {
            internalname
          }
        }
      }
      tags {
        tagid
        name
        tagtype {
          internalname
        }
      }
      traits {
        name
        internalname
      }
      product {
        productid
        active
        prices {
          productpriceid
          price
        }
        discountcoupons {
          couponcode
        }
      }
    }
  }
`;

export const othersProjectPostQuery = `
    query othersProjectPostQuery(
        $tags: [Int],
        $page: Int,
        $perpage: Int,
        $postTypeId: Int
    ){
        homeposts(tags: $tags, page: $page, perpage: $perpage, postTypeId: $postTypeId){
          posts{
                postid
                postthumbnail
                title
                postviewallhead
                postthumbnail
                postmobilecover
                postthumbnailcover
                creator {
                    fullname,
                    profilepicture,
                    occupationtitle,
                    shortbio,
                    uid,
                    alias
                    relatedmedia {
                      render
                      imagesettings {
                        imagesettingid
                        width
                        height
                        waspectratio
                        haspectratio
                        xcenter
                        ycenter
                      }
                      mediacontenttype {
                        internalname
                      }
                    }
                }
                traits {
                    name
                    internalname
                }
            }
            totalCount
            pageCount
            page
        }
      }
`
