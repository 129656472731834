/* eslint-disable @typescript-eslint/no-empty-function */
import { ActivePromotion } from "@frontend/unhideschool/app/shared-nxgs/application/application.model";
import { MembershipSignupInputModel } from "../models/actions/membership-signup-input.model";
import { UpgradeMembershipPlanPayloadModel } from "./membership.model";


export class FetchMembershipCoursesHighlight {
  static readonly type = '[MembershipState] Fetc Membership Courses Highlight';
  constructor(public activePromotion: ActivePromotion) { }
}

export class UpdateTeamPlanPrice {
  static readonly type = '[MembershipState] UpdateTeamPlanPrice';
  constructor() {}
}

export class FetchPlans {
  static readonly type = '[MembershipState] Fetch Prices';
  constructor() {}
}
export class FetchInstructor {
  static readonly type = '[FetchInstructorsData] Fetch instructors';
  constructor() {}
}

export class Signup {
  static readonly type = '[MembershipState] Signup';
  constructor(public payload: MembershipSignupInputModel) { }
}

export class UpdateUserAcceptEmailMarketing {
  static readonly type = '[MembershipState] Update User Accept Email Marketing';
  constructor(public id: number, public value: boolean) { }
}
