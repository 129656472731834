<div *ngIf="activePromo?.active" class="custom-promo-tag-wrapper">
  <div class="promolabel-wrapper" [style.font-size]="fontSize ? fontSize : '52px'" [class.flag-style]="isFlag || isCardFlag">
  <span class="promo-value">{{activePromo?.coupon?.value}}</span>
    <!-- <span class=" promo-value">15</span> -->
    <span class=" promo-percentage"> {{activePromo?.coupon?.discountcoupontype?.internalname === "amount" ? "R$" : "%"}}
      <span class=" promo-off">OFF</span>
    </span>

  </div>
  <img *ngIf="isCircle && !plus" [width]="width"  src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/svg-icons/promo/promo_tag.svg" alt="">
  <img *ngIf="isCircle && plus " [width]="width"  src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/svg-icons/promo/promo_tag_plus.svg" alt="">
  <img *ngIf="isFlag" [width]="width"  src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/svg-icons/promo/flag.svg" alt="">
  <img *ngIf="isCardFlag" [width]="width"  src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/svg-icons/promo/flag-card.svg" alt="">
</div>
