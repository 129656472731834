import { AfterViewInit, Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '@frontend/unhideschool/env';
import { Subject } from 'rxjs';

@Component({
  selector: 'unh-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss']
})
export class CookieDialogComponent {
  policyUsageUrl = environment.policyUsageUrl
  @Output() afterClosed = new EventEmitter<boolean>();
  constructor(
  ) { }

  confirm() {
    localStorage.setItem(environment.storagekeys.unhcookieaccept, 'accepted');
    this.close();
  }

  close() {
    this.afterClosed.next(true);
  }

}
