<div class="cookie-confirm-dialog-container">
  <div class="cookie-confirm-dialog-wrapper">
    <div class="cookie-warning-text-wrapper">
      <h1>
        Hey, calma aí!
      </h1>
      <p>
        A UNHIDE School utiliza cookies para melhorar a sua experiência.
        Saiba mais em nossa <strong><a  class="terms"
          [href]="policyUsageUrl"
          target="_blank">Política de Privacidade</a></strong>.
          <span>Ao utilizar nosso site, você concorda com o uso de cookies.</span>
      </p>
    </div>
    <div class="actions-wrapper">
      <unh-button color="gradient" (click)="confirm()">Entendi</unh-button>
    </div>
    <!-- <div class="bg-icons"></div> -->
  </div>
</div>
