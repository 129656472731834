import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { BreakpointObserver } from '@angular/cdk/layout';

import { Observable, of } from 'rxjs';
import {
  ViewAllFeatureBaseItem,
  viewAllFeaturesBuilder,
} from '../../models/view-all-feature.model';
import { LessonItem } from '@frontend/unhideschool/shared/models/project/lesson-item.model';
import { CollectionGroupItem } from '@frontend/unhideschool/app/helpers/group-collection-by-key.func';
import { CheckoutService } from '@frontend/unhideschool/core/services/checkout.service';
import { environment } from '@frontend/unhideschool/env';
import { UserApiService } from '@frontend/unhideschool/shared/services/api-client/user-api.service';
import { Gallery } from '@frontend/unhideschool/shared/models/dtos/gallery.model';
import { GalleriesSelectors } from '@frontend/unhideschool/app/shared-nxgs/galleries/galleries.selectors';
import { Select, Store } from '@ngxs/store';
import { ViewAllDialogModel } from '@frontend/unhideschool/app/utils/i18n/locale/view-all-dialog/view-all-dialog.i18n.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationSelectors } from '@frontend/unhideschool/app/shared-nxgs/application/application.selectors';
import { ProjectPost } from '@frontend/unhideschool/app/shared/models/project/project-post.model';
import { Post } from './../../../../shared/models/dtos/posts.model';
import { UnreadUserNotification } from '@frontend/unhideschool/app/core/models';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';
import { UserNotificationsStore } from '@frontend/unhideschool/app/core/services/legacy/user-notifications.store';
import { SideMenuStateService } from '@frontend/unhideschool/app/core/services/legacy/side-menu.state';
import { getProfilePicture } from '@frontend/unhideschool/app/helpers/relatedmedia-and-crop.helper';
import { getAppAssetPath } from '@frontend/web-components/src/utils/utils';
import { NormalizedSubscriptionPlan } from '../../../membership/models/membership-normalized-plan.model';
import { ViewallRouterDataModel } from '../../models/view-all-router-data.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilityService } from '@frontend/unhideschool/app/shared/services/utility.service';
import { PostTypeConfig } from '@frontend/unhideschool/app/shared/enums/post-type.enum';

@Component({
  selector: 'unh-view-all.dialog.ts',
  templateUrl: './view-all-dialog.component.html',
  styleUrls: ['./view-all-dialog.component.scss'],
})
export class ViewAllDialogComponent implements OnInit, OnDestroy {
  viewAllDialogMock: ViewAllDialogModel;

  private _projectGalleries: Observable<[]> = null;

  @Output() navigate = new EventEmitter<{ nextPrevPost: any }>();
  @Output() navigateToRoute = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSlideChange = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDownloadAsset = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCancelDownload = new EventEmitter<any>();

  @Output() navigatedToLesson = new EventEmitter<any>();

  @Input() groupedLessons: CollectionGroupItem<LessonItem>[] = [];
  @Input() mediafiles: { name?: string; url?: string;[x: string]: any }[] = [];
  @Input() projects: Post[] = [];
  @Input() project: ProjectPost;
  @Input() totalViews = 0;
  @Input() mentoringTopicUrl: string = null;
  @Input() routerData: ViewallRouterDataModel;
  @Input() hasActivePromo: any;
  @Input() activePromo: any;

  @Input() maxIndex = 0;
  @Input() currIndex = 0;

  features: ViewAllFeatureBaseItem[];
  unreadNotifs$: Observable<UnreadUserNotification[]>;
  mePurchasedProducts$: Observable<number[]>;

  panelOpenState = false;
  faqExpanded = false;
  lessonsExpanded = false;
  userLiked = false;
  ischeckingout = false;
  currentStylesAdminStyles;

  parentGallery: Gallery;

  standaloneArrows = false;
  nextPost = [];
  prevPost = [];
  issubscribed: boolean;

  isMobile: boolean;
  cdnUrl = environment.cdnUrl;
  mediapoolIconUrl = getAppAssetPath(
    'assets/images/icons/viewall/ico-cloud-download.png'
  );
  get isSmallScreen() {
    return this.breakpointObserver.isMatched('(max-width: 991px)');
  }

  @Select(ApplicationSelectors.hasActivePromotion())
  hasActivePromo$: Observable<boolean>;

  @Select(ApplicationSelectors.hasAnualPromo())
  hasAnualPromo$: Observable<boolean>;

  promoDiscountPercentage: number;
  anualProductPriceWithDiscount;
  anualPlan: NormalizedSubscriptionPlan;

  postTypeConfig = this.getPostTypeByRouter();

  constructor(
    public luss: LoggedUserStateService,
    private uns: UserNotificationsStore,
    private smss: SideMenuStateService,
    private breakpointObserver: BreakpointObserver,
    private r2: Renderer2,
    private cks: CheckoutService,
    private uapis: UserApiService,
    private store: Store,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<ViewAllDialogComponent>,
    private router: Router,
    private utils: UtilityService
  ) {
    this.unreadNotifs$ = this.uns.selectState<UnreadUserNotification[]>(
      'unreadNotifications'
    );
    this.features = viewAllFeaturesBuilder(null);
    this.mePurchasedProducts$ = this.luss.isLogged
      ? this.uapis.getUserPurchases()
      : of([]);
  }

  ngOnInit(): void {
    const htmlEl = document.querySelector('html');
    this.r2.setStyle(htmlEl, 'overflow-y', 'hidden');

    this.currentStylesAdminStyles = {
      position: this.isAdmin() ? 'absolute' : 'relative',
      'margin-top': this.isAdmin() ? '10px' : '0',
    };

    this.populatePromoDiscountValues();

    this.store.select(ApplicationSelectors.getAnualPlan()).subscribe((plan) => {
      this.anualPlan = plan;
    });
  }

  ngOnDestroy() {
    const htmlEl = document.querySelector('html');
    this.r2.setStyle(htmlEl, 'overflow-y', 'unset');
  }

  populatePromoDiscountValues() {
    this.store
      .select(ApplicationSelectors.getActivePromotion())
      .subscribe((promo) => {
        if (promo.initialized && promo.active && promo.coupon) {
          this.promoDiscountPercentage = promo.coupon.value / 100;
          const calculatedValue =
            (528 - 528 * this.promoDiscountPercentage) / 12;
          this.anualProductPriceWithDiscount = Math.ceil(calculatedValue);
        }
      });
  }
  openNotificationsMenu() {
    this.smss.setMenuOptions('notifications_menu', { isopen: true });
  }

  openUserMenu() {
    this.smss.setMenuOptions('user_menu', { isopen: true });
  }

  openMobileMenu() {
    this.smss.setMenuOptions('mobile_user_menu_notifications', {
      isopen: true,
    });
  }

  togglePanelExpanded(panelExpandedKey: string, matPanel: MatAccordion) {
    this[panelExpandedKey] = !this[panelExpandedKey];

    if (this.lessonsExpanded) {
      matPanel.openAll();
    } else {
      matPanel.closeAll();
    }
  }

  navigateTo(nextPrevPost: any) {
    this.navigate.emit(nextPrevPost);
  }

  checkoutProduct(productid, productpriceid) {
    this.ischeckingout = true;

    this.cks.checkoutOrder(productid, productpriceid).subscribe((res) => {
      this.ischeckingout = false;
      window.location.href = res.getCheckoutUrl(
        `${environment.checkoutpath}/order`
      );
    });
  }
  parentGalleryTeste(val) {
    if (this._projectGalleries == null) {
      this._projectGalleries = this.store.select(
        GalleriesSelectors.getProjectGalleries(
          this.project.galleries[0].galleryid
        )
      );
      this._projectGalleries.subscribe((gallery: any) => {
        this.parentGallery = gallery;
      });
    }
  }

  listenFilter($event) {
    // Not implemented
  }

  loadMoreGalleries(e?) {
    // Not implemented
  }

  isAdmin(): boolean {
    return this.luss.isAdmin;
  }

  isSubscribed(): boolean {
    return this.luss.subscribed;
  }

  isComingsoon(post) {
    return post.traits.some((trait) => trait.internalname == 'comingsoon');
  }

  loadmoreOtherProjects({ page }) {
    this.onSlideChange.emit(page);
  }

  closeDialog() {
    this.dialogRef.close();
  }
  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  downloadAsset(file) {
    this.onDownloadAsset.emit(file);
  }

  cancelDownload(file) {
    this.onCancelDownload.emit(file);
  }

  getCroppedImage(url, imagesettings) {
    return getProfilePicture(url, imagesettings, 255);
  }

  convertMsIntoHoursAndMinutes(timeMs) {
    const hours = Math.floor(timeMs / (1000 * 60 * 60));
    const minutes = Math.floor((timeMs / 1000 / 60) % 60);
    const formattedHours = hours > 0 ? `${hours}h ` : '0h';
    const formattedMinutes =
      minutes > 0 ? `${minutes.toString().padStart(2, '0')}m` : '';
    return `${formattedHours} ${formattedMinutes}`;
  }



  handleNavigateToLesson() {
    this.closeDialog();
    this.navigatedToLesson.emit(true)
  }

  playLesson() {
    this.handleNavigateToLesson();
  }

  getPostTypeByRouter() {
    return Object.values(PostTypeConfig).find((postType) =>
      this.router.routerState.snapshot.url.includes(postType.viewAllPath)
    );
  }

  isCourse() {
    return this.postTypeConfig === PostTypeConfig.COURSE;
  }
}
