import { FocusMonitor } from '@angular/cdk/a11y';
import { Inject } from '@angular/core';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Class } from 'utility-types';

interface DialogData {
  text?: {
    title?: string;
    message?: string;
  };
  size?: 'small' | 'default';
  buttons?: {
    text: string;
    type: 'default' | 'outline';
    action?: Class<any>;
    response?;
  }[];
  observer?: Observable<any>;
  action?: Class<any>;
  actionArgs: string[] | number[];
}

@Component({
  selector: 'unh-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements AfterViewInit {
  spinnerControl: boolean;

  generalClass: {
    [key: string]: boolean;
  };

  constructor(
    private dialogRef: MatDialogRef<ConfirmationComponent>,
    private fm: FocusMonitor,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data?: DialogData
  ) {
    this.generalClass = {
      'dialog-wrapper': true,
      [this.data.size]: !!this.data.size,
    };
  }

  ngAfterViewInit() {
    this.fm.stopMonitoring(document.getElementById('cancel-button'));
  }

  response(response: boolean) {
    if (response && this.data.action != undefined) {
      this.spinnerControl = true;

      this.store
        .dispatch(new this.data.action(...(this.data.actionArgs || [])))
        .pipe(
          catchError(() => of(this.dialogRef.close('Houve um Erro no Update')))
        )
        .subscribe(() => {
          this.spinnerControl = false;
          this.dialogRef.close(true);
        });
    }
    this.dialogRef.close(response);
  }
}
