
export class AddViewAllGalleryFilter{
  static readonly type = '[Filter Home Gallery] Update';
  constructor(public name: string | boolean,  public value?: string | boolean | number) {}
}
export class RemoveViewAllGalleryFilter{
  static readonly type = '[Filter Home Gallery] Remove';
  constructor(public name: string) {}
}
export class updateViewAllGalleryPagination{
  static readonly type = '[Pagination Home Gallery] Update';
  constructor(public page: number,public perpage: number) {}
}

export class FetchOthersPosts{
  static readonly type = '[Other Posts] fetch';
  constructor(public page: number,public perpage: number, public postTypeId: number = 1) {}
}

export class LoadmoreOthersPosts{
  static readonly type = '[Other Posts] loadmore';
  constructor(public page: number,public perpage: number, public postTypeId: number = 1) {}
}

export class UpdateOthersPostsPagination{
  static readonly type = '[Other Posts] Update Pagination';
  constructor(public page: number,public perpage: number) {}
}
export class FetchViewallLessons{
  static readonly type = '[Other Posts] GetViewallLessons';
  constructor(public postid: number) {}
}


export class FetchMentoringData{
  static readonly type = '[Other Posts] FetchMentoringData';
  constructor(public postid: number) {}
}

export class FetchLastWatchedLesson {
  static readonly type = '[ViewAll] Fetch Last Watched Lesson';
  constructor(public postId: number) { }
}

