import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '../auth.service';
import { MeInfoUser, UserData } from '../../models/logged-user.model';
import { UNHUserProfile } from '../../models/unh-user.model';
import { getProfilePicture } from '@frontend/unhideschool/app/helpers/relatedmedia-and-crop.helper';

@Injectable({
  providedIn: 'root',
})
export class LoggedUserStateService {
  private readonly _user$ = new BehaviorSubject<MeInfoUser>(null);
  private readonly _userProfileInfo$ = new BehaviorSubject<UNHUserProfile>(null);
  private readonly _courseListIds$ = new BehaviorSubject<number[]>([]);
  private readonly _subscribed$ = new BehaviorSubject<boolean>(false);
  private readonly _isLogged$ = new BehaviorSubject<boolean>(false);
  private readonly _isAdmin$ = new BehaviorSubject<boolean>(false);
  private readonly _isGuardian$ = new BehaviorSubject<boolean>(false);

  public user$ = this._user$.asObservable();
  public userProfileInfo$ = this._userProfileInfo$.asObservable();
  public courseListIds$ = this._courseListIds$.asObservable();
  public subscribed$ = this._subscribed$.asObservable();
  public isLogged$ = this._isLogged$.asObservable();
  public isAdmin$ = this._isAdmin$.asObservable();
  public isGuardian$ = this._isGuardian$.asObservable();

  get uid() {
    return this.user && this.user.uid;
  }

  get user() {
    return this._user$.value;
  }

  get userProfileInfo() {
    return this._userProfileInfo$.value;
  }

  get subscribed() {
    return this._subscribed$.value;
  }

  get isLogged() {
    return this._isLogged$.value;
    
  }

  get isAdmin() {
    return this._isAdmin$.value;
  }
  
  get isGuardian() {
    return this._isGuardian$.value;
  }

  get courseListIds() {
    return this._courseListIds$.value;
  }


  
  constructor(private auths: AuthService) {
    this.auths.authData$.subscribe((authData) => {
      try {
        const { me } = authData;
        this.updateLoggedUserStates(me?.user, me?.courseListIds, me?.subscribed);
      } catch {
        this.updateLoggedUserStates(null, [], false);
      }
    });
  }

  getProfilePicture() {
    const url = this.user?.profilepicture || '';
    const relatedmedia = this.userProfileInfo?.relatedmedia;
    return getProfilePicture(url, relatedmedia);
  }

  updateLoggedUserProfileInfo(userProfile: Partial<UserData>) {
    this._userProfileInfo$.next(userProfile);
  }

  private updateLoggedUserStates(
    user: MeInfoUser,
    courseListIds: number[] = [],
    subscribed: boolean = false
  ) {
    this._user$.next(user);
    this._userProfileInfo$.next(user);
    this._courseListIds$.next(courseListIds);
    this._subscribed$.next(subscribed);
    this._isLogged$.next(user != null);
    this._isAdmin$.next(user?.rolename === 'adminuser');
    this._isGuardian$.next(user?.rolename === 'career_guardian');
  }
}
