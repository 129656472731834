/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Subscriptionplan } from './membership-root-plan.model';
import { Product } from './membership-plan-product.model';
import { asNumber, safeJsonParse } from '@frontend/unhideschool/app/helpers/utility-functions';
import { ActivePromotion } from '@frontend/unhideschool/app/shared-nxgs/application/application.model';

export interface MembershipSubscriptionPlansStoreModel {
  plans: NormalizedSubscriptionPlan[] | NormalizedPlanProduct[];
}

export class NormalizedPlanBaseModel {
  free: boolean;
  title: string;
  name: string;
  subtitle: string;
  cycle_months: number;
  description: string;
  subsplanpriceid?: number;
  subsplanid?: number;
  productpriceid?: number;
  productid?: number;
  internalname?: string;
  installments?: number;
  coupons?: any[];
  subscriptionPrice?: number;
  invidualTotalPrice?: number;
  price?: number;
  savedMoney?: number;
  originalPrice: string;
  currentPrice: string;
  campaign: ICampaign;
}

export interface ICampaign {
  campaignName: string;
  active: boolean;
  discountCouponId: string;
  discountCoupon: IDiscountCoupon;
  coupon: IDiscountCoupon;
  initialized?: boolean;
}

interface IDiscountCoupon {
  active: boolean;
  name: string;
  couponcode: string;
  value: number;
  cycles: number | undefined;
  validuntil: string;
  datecreated: string;
  discountcoupontype: {
    internalname: string;
  };
}

enum PlanInstallmentsByTitle {
  Trimestral = 3,
  Semestral = 6,
  Anual = 12
}
export class NormalizedSubscriptionPlan extends NormalizedPlanBaseModel {
  subsplanpriceid?: number;
  subsplanid?: number;
  cycle_months = 1;
  hasActivePromo = false;
  activePromotion: ActivePromotion;
  constructor(subscriptionPlan: Subscriptionplan) {
    super();
    this.name = subscriptionPlan.name;
    this.free = false;
    this.description = subscriptionPlan.description;
    this.subsplanid = subscriptionPlan.subsplanid;
    // Additional Info
    const adinfo = safeJsonParse(subscriptionPlan?.additionalinfo);
    if (adinfo != null) {
      this.title = adinfo.title;
      this.subtitle = adinfo.subtitle;
      this.cycle_months = adinfo.duration;
    }

    // Subscription Plan
    this.internalname = subscriptionPlan.internalname;
    this.subsplanid = subscriptionPlan.subsplanid;

    // Subscription Plan Price
    const priceData = subscriptionPlan.prices[0];
    this.originalPrice = subscriptionPlan.originalPrice;
    this.currentPrice = subscriptionPlan.currentPrice
    this.campaign = subscriptionPlan.campaign
    this.price = asNumber(subscriptionPlan.currentPrice)

    if(this.campaign){
      this.campaign.coupon = subscriptionPlan.campaign?.discountCoupon
    }
    this.subsplanpriceid = priceData.subsplanpriceid;
    this.coupons = subscriptionPlan.discountcoupons || [];

  }
}

export class NormalizedPlanProduct extends NormalizedPlanBaseModel {
  productpriceid?: number;
  productid?: number;

  individualTotalPrice: number;

  _currentPrice: number;

  _productTotalPrice?: number;
  get productTotalPrice(): string {
    return formatPrice(this._productTotalPrice)
  }

  _productDiscountTotalPrice?: number;
  get productDiscountTotalPrice(): string {
    return formatPrice(this._productDiscountTotalPrice)
  }

  _monthlyPrice?: number;
  get monthlyPrice(): string {
    return formatPrice(this._monthlyPrice)
  }

  _discountMonthlyPrice?: number;
  get discountMonthlyPrice(): string {
    return formatPrice(this._discountMonthlyPrice)
  }


  constructor(planProduct: Product, public individualPlanPrice?: number, activePromo?: ActivePromotion) {
    super();
    this.free = false;
    this.title = planProduct.name;
    this.description = planProduct.description;
    // Additional Info
    if (planProduct.additionalinfo != null) {
      const additionalInfo = safeJsonParse(planProduct.additionalinfo);
      this.title = additionalInfo.title;
      this.subtitle = additionalInfo.subtitle;
      this.cycle_months = additionalInfo.duration;
    }

    // Plan Product
    this.internalname = planProduct.internalname;
    this.productid = planProduct.productid;

    // Plan Product Price
    const priceData = planProduct.prices[0];
    this._productTotalPrice = priceData.price;
    this.productpriceid = priceData.productpriceid;
    this.individualTotalPrice = individualPlanPrice * this.cycle_months;
    // planProduct.price ===> Total com desconto
    if (activePromo && activePromo.active) {
      const hasCouponCode = planProduct?.discountcoupons?.some(item => item.couponcode.toLowerCase() == activePromo?.coupon?.couponcode.toLowerCase());
      this._productDiscountTotalPrice = this._productTotalPrice;
      if (hasCouponCode) {
        this._productDiscountTotalPrice = this.calculateDiscountCouponToProductPrice(activePromo);
      }

    }

    this._monthlyPrice = this._productTotalPrice / this.cycle_months;

    this._discountMonthlyPrice = this._productDiscountTotalPrice / this.cycle_months;

    this.installments = PlanInstallmentsByTitle[this.title];

    let currentTotalPrice = this._productTotalPrice;
    this._currentPrice = this._monthlyPrice;

    if (activePromo && activePromo.active) {
      const hasCouponCode = planProduct?.discountcoupons?.some(item => item.couponcode.toLowerCase() == activePromo?.coupon?.couponcode.toLowerCase());

      if (hasCouponCode) {
        this._currentPrice = this._discountMonthlyPrice;
        currentTotalPrice = this._productDiscountTotalPrice;
      }
    }

    this.savedMoney = formatPrice(this.individualTotalPrice - currentTotalPrice);
    this.coupons = planProduct.discountcoupons;
  }


  private calculateDiscountCouponToProductPrice(activePromo) {
    const discountPercentage = activePromo.coupon.value / 100;
    const discountValue = this._productTotalPrice * discountPercentage;
    return this._productTotalPrice - discountValue;
  }

}

function formatPrice(number) {
  if (Number.isFinite(number) && !Number.isInteger(number)) {
    return number.toFixed(2);
  }

  return number ? number.toString() : '0';
}



export const calculatePercentageDiscountPrice = (price: number, percentage) => {
  const discountPercentage = percentage / 100;
  const rawDiscountValue = price * discountPercentage;
  return price - rawDiscountValue;
}

const hasCouponCode = (subscriptionPlan: Subscriptionplan, activePromotion: ActivePromotion) => {
  if (activePromotion.active) {
    const hasCoupon = subscriptionPlan?.discountcoupons?.findIndex(dc => dc.couponcode == activePromotion.coupon.couponcode) >= 0;
    return hasCoupon;
  }
  return false;

}