export const FetchGuardianCareerCommunityQuery = `
query FetchGuardianCareerCommunity($communityname: String) {
  Community {
    all(path: $communityname, active: false) {
      items {
        path
        communitycategid
        datecreated
        active
        communityid
        category {
          name
        }
      }
    }
  }
}

`;
